import React from "react";

// Material
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import Fab from "@material-ui/core/Fab";

import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";

import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import BarChartIcon from "@material-ui/icons/BarChart";
import SwapHorizOutlinedIcon from "@material-ui/icons/SwapHorizOutlined";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import SaveIcon from "@material-ui/icons/Save";
import ReplyIcon from "@material-ui/icons/Reply";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";

import Brightness2OutlinedIcon from "@material-ui/icons/Brightness2Outlined";
import WbSunnyOutlinedIcon from "@material-ui/icons/WbSunnyOutlined";

import {
  BarChart,
  Bar,
  XAxis,
  ResponsiveContainer,
  Tooltip as ChartTooltip,
} from "recharts";

import { MobileView, isIOS, isAndroid } from "react-device-detect";

import cookie from "react-cookies";

// Others
import { Line } from "rc-progress";
import axios from "axios";
import MetaTags from "react-meta-tags";

// Tooltip
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    fontSize: 18,
    backgroundColor: theme.palette.common.black,
  },
}));
function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

// Styling
const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(2),
    backgroundColor: "#4192F7",
  },
  calculationName: {
    fontSize: "1.2rem",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  inputGroup: {
    height: 90,
  },
  simpleSelect: {
    height: 90,
    marginTop: theme.spacing(2),
  },
  resultLabel: {
    textAlign: "center",
    lineHeight: 1.2,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2) - 5,
    fontSize: "1.8rem",
  },
  resultDecimal: {
    display: "inline-block",
    fontSize: "1.2rem",
    lineHeight: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function App() {
  const classes = useStyles();

  const [darkState, setDarkState] = React.useState(true);

  const mainPrimaryColor = darkState ? "#4192F7" : "#4192F7";
  const mainSecondaryColor = darkState ? "#66dd9c" : "#66dd9c";
  const backgroundColor = darkState ? "#232A43" : "white";
  const backgroundColorDark = darkState ? "#16192E" : "#EDF0F6";
  const lightColor = darkState ? "#AAAAAA" : "#CCCCCC";

  const palletType = darkState ? "dark" : "light";
  const darkTheme = createMuiTheme({
    typography: {},
    palette: {
      type: palletType,
      primary: {
        main: mainPrimaryColor,
      },
      secondary: {
        main: mainSecondaryColor,
        light: lightColor,
      },
      background: {
        default: backgroundColor,
        dark: backgroundColorDark,
      },
    },
  });

  const [values, setValues] = React.useState({
    BookmarkID: 0,
    PageName: "Compound Interest Calculator",
    NewPageName: "",
    CurrentAmount: "",
    InvestmentLength: "",
    AnnualReturnRate: "",
    PeriodicContribution: "",
    PeriodWarning: "",
    SimpleFrequencyRow: 2,
    ContributionFrequency: "12",
    ContributionWarning: "",
    CompoundFrequency: "12",
    CompoundWarning: "",
    Contribution: "0",
    Profit: "0",
    FinalResult: 0,
    TableResult: [],
    ChartData: [],
  });

  const CurrentAmountRef = React.useRef();
  const resetValues = function () {
    setValues({
      BookmarkID: 0,
      PageName: "Compound Interest Calculator",
      NewPageName: "",
      CurrentAmount: "",
      InvestmentLength: "",
      AnnualReturnRate: "",
      PeriodicContribution: "",
      PeriodWarning: "",
      SimpleFrequencyRow: 2,
      ContributionFrequency: "12",
      ContributionWarning: "",
      CompoundFrequency: "12",
      CompoundWarning: "",
      Contribution: "0",
      Profit: "0",
      FinalResult: "0",
      TableResult: [],
      ChartData: [],
    });
    CurrentAmountRef.current.focus();
  };

  // Format number ex. 10,000,000.00
  const commaSeparateNum = (text) => {
    if (isNaN(parseFloat(text))) {
      return "";
    }
    text = text.toString();
    text = text.replace(/,/g, "");
    text = text.replace(/[^0-9,.,-]/, "");
    text = text.replace(/ /g, "");
    text = text + "";
    var x = text.split(".");
    var x1 = x[0];
    var x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      var replaceWith = "$1,$2";
      x1 = x1.replace(rgx, replaceWith);
    }
    return x1 + x2;
  };

  // Get Frequency from Select
  const GetFrequency = function (NewValue) {
    if (NewValue === 0) {
      return "1";
    } else if (NewValue === 1) {
      return "4";
    } else if (NewValue === 2) {
      return "12";
    } else if (NewValue === 3) {
      return "52";
    } else if (NewValue === 4) {
      return "365";
    }
  };

  // Dollar Format
  const dollarFormat = (text) => {
    var Num = parseFloat(text.replace(/,/g, ""));
    Num = (Math.round(Num * 100) / 100).toFixed(2);
    if (Num < 0) {
      Num = Num * -1;
      return "-$" + commaSeparateNum(Num.toString());
    } else {
      return "$" + commaSeparateNum(Num.toString());
    }
  };
  const dollarFormatInt = (text) => {
    var Num = parseFloat(text.replace(/,/g, ""));
    Num = (Math.round(Num * 100) / 100).toFixed(0);
    if (Num < 0) {
      Num = Num * -1;
      return "-$" + commaSeparateNum(Num.toString());
    } else {
      return "$" + commaSeparateNum(Num.toString());
    }
  };

  // Get Decimal Value
  const getDecimal = function (Number) {
    var x = Number.split(".");
    if (x.length > 1) {
      // 1000 50 10 50000
      var Decimal = Math.round(parseFloat("0." + x[1]) * 100);
      if (Decimal >= 100) {
        Decimal = 99;
      } else if (Decimal < 10) {
        Decimal = "0" + Decimal;
      }
      return Decimal;
    } else {
      return "00";
    }
  };

  // Check is Valid Number
  const isNumber = function (n) {
    return typeof n == "number" && !isNaN(n) && isFinite(n);
  };

  // Calculate Final Result
  const CalculateResult = function () {
    // Current Amount
    var P = 0;
    if (parseFloat(values.CurrentAmount.toString().replace(/,/g, "")) > 0) {
      P = parseFloat(values.CurrentAmount.toString().replace(/,/g, ""));
    }

    // Periodic Contribution
    var PMT = 0;
    if (
      isNumber(
        parseFloat(values.PeriodicContribution.toString().replace(/,/g, ""))
      )
    ) {
      PMT = parseFloat(
        values.PeriodicContribution.toString().replace(/,/g, "")
      );
    }

    // Annual Return Rate
    var r = 0;
    if (parseFloat(values.AnnualReturnRate.toString().replace(/,/g, "")) > 0) {
      r = parseFloat(values.AnnualReturnRate.toString().replace(/,/g, ""));
      r = r / 100;
    }

    // Investment Length
    var t = 0;
    if (parseFloat(values.InvestmentLength.toString().replace(/,/g, "")) > 0) {
      t = parseFloat(values.InvestmentLength.toString().replace(/,/g, ""));
      if (t > 200) {
        t = 200;
      }
    }

    // Compound Frequency
    var n = parseFloat(values.CompoundFrequency.toString().replace(/,/g, ""));
    if (n > 0) {
      if (n > 365) {
        n = 365;
      }
    } else {
      n = 0;
    }
    // Contribution Frequency
    var contFreq = parseFloat(
      values.ContributionFrequency.toString().replace(/,/g, "")
    );
    if (values.CompoundFrequency !== values.ContributionFrequency) {
      if (n > 0) {
        PMT = (PMT * contFreq) / n;
      } else {
        PMT = 0;
      }
    } else if (n == 0) {
      PMT = 0;
    }

    // Rate > 0 AND Compound Frequency > 0
    var Result = "N/A";
    var Contribution = 0;
    var Profit = 0;
    //var NewTable = [];
    if (r > 0 && n > 0) {
      var CI = P * Math.pow(1 + r / n, n * t);
      var FV = PMT * ((Math.pow(1 + r / n, n * t) - 1) / (r / n));
      Result = CI + FV;
      Contribution = P + PMT * n * t;
      Profit = Result - Contribution;
    } else {
      Contribution = P + PMT * n * t;
      Result = Contribution;
    }

    var NewTable = [];
    var NewChartData = [];
    if (t > 0) {
      NewTable.push(["Period", "End Balance"]);
      for (let i = 0; i <= Math.ceil(t); i++) {
        var Cont = 0;
        var Res = 0;
        if (r > 0 && n > 0) {
          CI = P * Math.pow(1 + r / n, n * i);
          FV = PMT * ((Math.pow(1 + r / n, n * i) - 1) / (r / n));
          Cont = P + PMT * n * i;
          Res = CI + FV;
        } else {
          Cont = P + PMT * n * i;
          Res = Cont;
        }

        var Year = "Year " + i;
        var Prof = Res - Cont;

        NewChartData.push({
          Year: Year,
          YearNum: i,
          Profit: Prof,
          Contribution: Cont,
          Total: Res,
        });

        NewTable.push([i, Res, Prof, Cont]);
      }
    }

    setValues({
      ...values,
      Contribution: Contribution,
      Profit: Profit,
      FinalResult: Result,
      TableResult: NewTable,
      ChartData: NewChartData,
    });
  };

  React.useEffect(() => {
    CalculateResult();
  }, [
    values.CurrentAmount,
    values.InvestmentLength,
    values.AnnualReturnRate,
    values.PeriodicContribution,
    values.ContributionFrequency,
    values.CompoundFrequency,
    values.SimpleFrequencyRow,
  ]);

  const checkNaN = function (Input) {
    if (isNaN(parseFloat(Input))) {
      return "";
    } else {
      return Input.toString();
    }
  };

  const [inputFocus, setInputFocus] = React.useState({
    CurrentAmount: false,
    InvestmentLength: false,
    AnnualReturnRate: false,
    PeriodicContribution: false,
    ContributionFrequency: false,
    CompoundFrequency: false,
  });

  const toggleFocus = function (Label) {
    if (!inputFocus[Label]) {
      setValues({
        ...values,
        [Label]: commaSeparateNum(values[Label].toString()),
      });
    } else {
      setValues({
        ...values,
        [Label]: parseFloat(values[Label].toString().replace(/,/g, "")),
      });
    }
  };

  React.useEffect(() => {
    toggleFocus("CurrentAmount");
  }, [inputFocus.CurrentAmount]);
  React.useEffect(() => {
    toggleFocus("AnnualReturnRate");
  }, [inputFocus.AnnualReturnRate]);
  React.useEffect(() => {
    toggleFocus("PeriodicContribution");
  }, [inputFocus.PeriodicContribution]);

  const [loadingData, setLoadingData] = React.useState(false);
  const getData = function () {
    var ShareKey = window.location.href.split("/")[3].split("?")[0];
    if (ShareKey.length > 0) {
      setLoadingData(true);
      axios
        .post("/api/get.php", {
          ShareKey: ShareKey,
        })
        .then(function (response) {
          var Values = response.data;
          var NewPageName = Values.PageName;
          if (NewPageName.indexOf("Web Save") !== -1) {
            NewPageName = "Compound Interest Calculator";
          }
          setValues({
            ...values,
            PageName: NewPageName,
            CurrentAmount: Values.CurrentAmount,
            InvestmentLength: Values.InvestmentLength,
            AnnualReturnRate: Values.AnnualReturnRate,
            PeriodicContribution: Values.PeriodicContribution,
            SimpleFrequencyRow: parseInt(Values.SimpleFrequency),
            ContributionFrequency: Values.ContributionFrequency,
            CompoundFrequency: Values.CompoundFrequency,
          });
          setLoadingData(false);
        })
        .catch(function (error) {
          console.log(error);
          setLoadingData(false);
        });
    } else {
      CurrentAmountRef.current.focus();
      //CalculateResult();
    }
  };

  const [savingData, setSavingData] = React.useState(false);
  const [shareOpen, setShareOpen] = React.useState(false);
  const saveData = function () {
    if (!savingData) {
      setSavingData(true);
      axios
        .post("/api/save.php", {
          CurrentAmount: values.CurrentAmount,
          InvestmentLength: values.InvestmentLength,
          AnnualReturnRate: values.AnnualReturnRate,
          PeriodicContribution: values.PeriodicContribution,
          SimpleFrequency: values.SimpleFrequencyRow,
          ContributionFrequency: values.ContributionFrequency,
          CompoundFrequency: values.CompoundFrequency,
        })
        .then(function (response) {
          //console.log(response);
          window.history.pushState(
            response.data.ShareKey,
            "Compound Interest Calculator",
            "/" + response.data.ShareKey
          );
          setValues({
            ...values,
            ShareKey: response.data.ShareKey,
          });

          setShareOpen(true);
          setSavingData(false);
        })
        .catch(function (error) {
          setSavingData(false);
        });
    }
  };

  React.useEffect(() => {
    getData();
    if (typeof cookie.load("lightMode") !== "undefined") {
      setDarkState(false);
    }
  }, []);

  const CustomChartTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            background: darkState ? "#1F253C" : "#EDF0F6",
            border: "1px solid #CCC",
            borderColor: darkState ? "black" : "#CCC",
            padding: 10,
          }}
        >
          <Typography color="inherit" style={{ fontWeight: "bold" }}>
            {label}
            {" · "}
            {dollarFormatInt((payload[0].value + payload[1].value).toString())}
          </Typography>
          <Typography color="inherit" style={{ fontSize: "1em" }}>
            {payload[0].name}: {dollarFormatInt(payload[0].value.toString())}
          </Typography>
          <Typography color="inherit" style={{ fontSize: "1em" }}>
            {payload[1].name}: {dollarFormatInt(payload[1].value.toString())}
          </Typography>
        </div>
      );
    }

    return null;
  };

  const CurrentDate = new Date();
  const CurrentYear = CurrentDate.getFullYear();

  return (
    <ThemeProvider theme={darkTheme}>
      <MetaTags>
        <meta property="og:title" content={values.PageName} />
      </MetaTags>
      <AppBar
        position="static"
        style={{
          background: darkState ? "#232A43" : "white",
          color: darkState ? "" : "black",
        }}
      >
        <Toolbar>
          <Tooltip title="New Calculation" aria-label="New" placement="right">
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              background="primary"
              aria-label="menu"
              onClick={() => {
                resetValues();
              }}
            >
              <img
                src={
                  darkState
                    ? require("./icon-transparent.png")
                    : require("./icon-transparent-light.png")
                }
                alt="Logo Transparent"
                style={{ width: 22 }}
              />
            </IconButton>
          </Tooltip>
          <Typography
            component="h1"
            variant="h6"
            className={classes.title}
            style={{
              textAlign: "center",
              noWrap: true,
              overflow: "hidden",
              textOverflow: "hidden",
              maxHeight: "1.6em",
              paddingRight: 10,
            }}
          >
            {!loadingData && values.PageName}
          </Typography>
          <Tooltip
            title={darkState ? "Light Mode" : "Dark Mode"}
            aria-label="Dark"
            placement="right"
          >
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => {
                var newDarkState = !darkState;
                if (!newDarkState) {
                  var expires = new Date();
                  expires.setDate(expires.getDate() + 365);
                  cookie.save("lightMode", true, {
                    path: "/",
                    expires: expires,
                  });
                } else {
                  cookie.remove("lightMode", { path: "/" });
                }
                setDarkState(newDarkState);
              }}
            >
              {!darkState && <Brightness2OutlinedIcon />}
              {darkState && <WbSunnyOutlinedIcon />}
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>

      <Container component="main" maxWidth="md">
        <CssBaseline />

        <form className={classes.form} noValidate>
          <Grid container spacing={3}>
            <Grid item md={3} xs={6} className={classes.inputGroup}>
              {!inputFocus.CurrentAmount && (
                <TextField
                  inputRef={CurrentAmountRef}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={"Initial Amount"}
                  value={commaSeparateNum(values.CurrentAmount.toString())}
                  onFocus={() => {
                    setInputFocus({ ...inputFocus, CurrentAmount: true });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">{""}</InputAdornment>
                    ),
                  }}
                />
              )}

              {inputFocus.CurrentAmount && (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id={"CurrentAmount"}
                  name={"CurrentAmount"}
                  label={"Initial Amount"}
                  value={checkNaN(values.CurrentAmount)}
                  type="number"
                  autoFocus
                  onBlur={() => {
                    setInputFocus({ ...inputFocus, CurrentAmount: false });
                  }}
                  onChange={(event) => {
                    if (event.target.value > 0 || event.target.value == "") {
                      setValues({
                        ...values,
                        CurrentAmount: event.target.value,
                      });
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">{""}</InputAdornment>
                    ),
                  }}
                />
              )}
            </Grid>

            <Grid item md={3} xs={6} className={classes.inputGroup}>
              {!inputFocus.AnnualReturnRate && (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={"Annual Return"}
                  value={commaSeparateNum(values.AnnualReturnRate.toString())}
                  onFocus={() => {
                    setInputFocus({ ...inputFocus, AnnualReturnRate: true });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{""}</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">{"%"}</InputAdornment>
                    ),
                  }}
                />
              )}

              {inputFocus.AnnualReturnRate && (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id={"AnnualReturnRate"}
                  name={"AnnualReturnRate"}
                  label={"Annual Return"}
                  value={checkNaN(values.AnnualReturnRate)}
                  type="number"
                  autoFocus
                  onBlur={() => {
                    setInputFocus({ ...inputFocus, AnnualReturnRate: false });
                  }}
                  onChange={(event) => {
                    if (event.target.value > 0 || event.target.value == "") {
                      setValues({
                        ...values,
                        AnnualReturnRate: event.target.value,
                      });
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{""}</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              )}
            </Grid>

            <Grid item md={3} xs={6} className={classes.inputGroup}>
              {!inputFocus.InvestmentLength && (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={"Duration"}
                  value={commaSeparateNum(values.InvestmentLength.toString())}
                  onFocus={() => {
                    setInputFocus({ ...inputFocus, InvestmentLength: true });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{""}</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">years</InputAdornment>
                    ),
                  }}
                />
              )}
              {inputFocus.InvestmentLength && (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id={"Duration"}
                  name={"Duration"}
                  label={"Duration"}
                  value={checkNaN(values.InvestmentLength)}
                  type="number"
                  autoFocus
                  onBlur={() => {
                    setInputFocus({ ...inputFocus, InvestmentLength: false });
                  }}
                  onChange={(event) => {
                    var NewValue = event.target.value;
                    if (NewValue > 200) {
                      NewValue = 200;
                    }

                    if (event.target.value > 0 || event.target.value == "") {
                      setValues({
                        ...values,
                        InvestmentLength: NewValue,
                      });
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{""}</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">years</InputAdornment>
                    ),
                  }}
                />
              )}
            </Grid>

            <Grid item md={3} xs={6} className={classes.inputGroup}>
              {!inputFocus.PeriodicContribution && (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Periodic Addition"
                  value={commaSeparateNum(
                    values.PeriodicContribution.toString()
                  )}
                  onFocus={() => {
                    setInputFocus({
                      ...inputFocus,
                      PeriodicContribution: true,
                    });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">{""}</InputAdornment>
                    ),
                  }}
                />
              )}
              {inputFocus.PeriodicContribution && (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id={"PeriodicContribution"}
                  name={"PeriodicContribution"}
                  label="Periodic Addition"
                  value={checkNaN(values.PeriodicContribution)}
                  type="number"
                  autoFocus
                  onBlur={() => {
                    setInputFocus({
                      ...inputFocus,
                      PeriodicContribution: false,
                    });
                  }}
                  onChange={(event) => {
                    setValues({
                      ...values,
                      PeriodicContribution: event.target.value,
                    });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">{""}</InputAdornment>
                    ),
                  }}
                />
              )}
            </Grid>

            {values.SimpleFrequencyRow < 5 && (
              <Grid item xs={12} className={classes.simpleSelect}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Compound &amp; Addition Frequency</InputLabel>
                  <Select
                    native
                    label="Compound &amp; Addition Frequency-"
                    inputProps={{
                      name: "age",
                      id: "outlined-age-native-simple",
                    }}
                    value={values.SimpleFrequencyRow}
                    onChange={(event) => {
                      var NewValue = parseInt(event.target.value);

                      if (NewValue !== 5) {
                        setValues({
                          ...values,
                          SimpleFrequencyRow: NewValue,
                          ContributionFrequency: GetFrequency(NewValue),
                          CompoundFrequency: GetFrequency(NewValue),
                        });
                      } else {
                        setValues({
                          ...values,
                          SimpleFrequencyRow: NewValue,
                        });
                      }
                    }}
                  >
                    <option value={0}>Annually | Once a year</option>
                    <option value={1}>Quarterly | 4 times a year</option>
                    <option value={2}>Monthly | 12 times a year</option>
                    <option value={3}>Weekly | 52 times a year</option>
                    <option value={4}>Daily | 365 times a year</option>
                    <option value={5}>Advanced</option>
                  </Select>
                </FormControl>
              </Grid>
            )}
            {values.SimpleFrequencyRow >= 5 && (
              <>
                <Grid item xs={6} className={classes.inputGroup}>
                  {!inputFocus.CompoundFrequency && (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label={"Compound Freq"}
                      value={commaSeparateNum(
                        values.CompoundFrequency.toString()
                      )}
                      onFocus={() => {
                        setInputFocus({
                          ...inputFocus,
                          CompoundFrequency: true,
                        });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">×</InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">/ year</InputAdornment>
                        ),
                      }}
                    />
                  )}

                  {inputFocus.CompoundFrequency && (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id={"CompoundFrequency"}
                      name={"CompoundFrequency"}
                      label={"Compound Freq"}
                      value={checkNaN(values.CompoundFrequency)}
                      type="number"
                      autoFocus
                      onBlur={() => {
                        setInputFocus({
                          ...inputFocus,
                          CompoundFrequency: false,
                        });
                      }}
                      onChange={(event) => {
                        var NewValue = event.target.value;
                        if (NewValue > 365) {
                          NewValue = 365;
                        }
                        setValues({
                          ...values,
                          CompoundFrequency: NewValue.toString(),
                        });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">×</InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">/ year</InputAdornment>
                        ),
                      }}
                    />
                  )}
                </Grid>

                <Grid item xs={6} className={classes.inputGroup}>
                  {!inputFocus.ContributionFrequency && (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label={"Addition Freq"}
                      value={commaSeparateNum(
                        values.ContributionFrequency.toString()
                      )}
                      onFocus={() => {
                        setInputFocus({
                          ...inputFocus,
                          ContributionFrequency: true,
                        });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">×</InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">/ year</InputAdornment>
                        ),
                      }}
                    />
                  )}

                  {inputFocus.ContributionFrequency && (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id={"ContributionFrequency"}
                      name={"ContributionFrequency"}
                      label={"Addition Freq"}
                      value={checkNaN(values.ContributionFrequency)}
                      type="number"
                      autoFocus
                      onBlur={() => {
                        setInputFocus({
                          ...inputFocus,
                          ContributionFrequency: false,
                        });
                      }}
                      onChange={(event) => {
                        var NewValue = event.target.value;
                        if (NewValue > 365) {
                          NewValue = 365;
                        }
                        setValues({
                          ...values,
                          ContributionFrequency: NewValue,
                        });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">×</InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">/ year</InputAdornment>
                        ),
                      }}
                    />
                  )}
                </Grid>

                <Grid item xs={12} className={classes.inputGroup}>
                  <Button
                    variant="outlined"
                    fullWidth
                    startIcon={<SwapHorizOutlinedIcon />}
                    onClick={() => {
                      setValues({
                        ...values,
                        SimpleFrequencyRow: 2,
                        ContributionFrequency: "12",
                        CompoundFrequency: "12",
                      });
                    }}
                  >
                    Simple Frequency
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </form>
        <Typography className={classes.resultLabel} component="h2">
          {Math.floor(values.FinalResult) < 0 ? "-$" : "$"}
          {isFinite(values.FinalResult) && values.FinalResult < 1000000000000 && (
            <>
              {commaSeparateNum(
                Math.abs(Math.floor(values.FinalResult)).toString()
              )}
              <Typography
                className={classes.resultDecimal}
                style={{
                  color: darkState
                    ? "rgba(255, 255, 255, 0.7)"
                    : "rgba(0, 0, 0, 0.54)",
                }}
              >
                .{getDecimal(values.FinalResult.toString())}
              </Typography>
            </>
          )}
          {(!isFinite(values.FinalResult) ||
            values.FinalResult >= 1000000000000) && <>1+ Trillion</>}
        </Typography>

        <Line
          percent={
            values.Contribution === 0 && values.Contribution === 0
              ? 100
              : (values.Contribution * 100) /
                (values.Contribution + values.Profit)
          }
          strokeLinecap="square"
          strokeColor="#4192F7"
          trailColor="#66DD9C"
          strokeWidth="0.8"
          trailWidth="0.8"
        />

        <Table size="small" style={{ marginTop: 20 }}>
          <TableBody>
            <TableRow>
              <TableCell
                align="left"
                style={{ padding: 0, borderBottom: "none" }}
              >
                <Grid container direction="row" alignItems="center">
                  <Grid style={{ lineHeight: 1 }} item>
                    <FiberManualRecordIcon style={{ color: "#4192F7" }} />
                  </Grid>
                  <Grid
                    style={{ lineHeight: 1, paddingLeft: 5, fontSize: "1rem" }}
                    item
                  >
                    Contribution
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell
                align="right"
                style={{ padding: 0, borderBottom: "none", fontSize: "1rem" }}
              >
                {dollarFormat(values.Contribution.toString())}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table size="small" style={{ marginTop: 10 }}>
          <TableBody>
            <TableRow>
              <TableCell
                align="left"
                style={{ padding: 0, borderBottom: "none" }}
              >
                <Grid container direction="row" alignItems="center">
                  <Grid style={{ lineHeight: 1 }} item>
                    <FiberManualRecordIcon style={{ color: "#66DD9C" }} />
                  </Grid>
                  <Grid
                    style={{ lineHeight: 1, paddingLeft: 5, fontSize: "1rem" }}
                    item
                  >
                    Profit
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell
                align="right"
                style={{ padding: 0, borderBottom: "none", fontSize: "1rem" }}
              >
                {dollarFormat(values.Profit.toString())}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        {values.ChartData.length > 0 && (
          <div
            style={{
              height: 300,
              marginTop: 40,
              userSelect: "none",
              WebkitTouchCallout: "none",
              WebkitUserSelect: "none",
              KhtmlUserSelect: "none",
              MozUserSelect: "none",
              MsUserSelect: "none",
            }}
          >
            <ResponsiveContainer>
              <BarChart width={500} height={300} data={values.ChartData}>
                <XAxis dataKey="Year" />

                <ChartTooltip
                  content={<CustomChartTooltip />}
                  cursor={{ fill: darkState ? "#1F253C" : "#EDF0F6" }}
                />
                <Bar
                  dataKey="Contribution"
                  stackId="a"
                  fill="#4192F7"
                  cursor="pointer"
                />
                <Bar
                  dataKey="Profit"
                  stackId="a"
                  fill="#66DD9C"
                  cursor="pointer"
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        )}

        <MobileView style={{ marginTop: 20 }}>
          {/* Android Google Play */}
          <a href="https://play.google.com/store/apps/details?id=com.fcalculators.cal001">
            <img
              src={require("./GooglePlay.png")}
              alt="Get it on Google Play"
              style={{ width: "49%" }}
            />
          </a>

          {/* Apple App Store */}
          <a href="https://apps.apple.com/us/app/compound-interest-calculator-f/id907424961">
            <img
              src={require("./AppStore.png")}
              alt="Download on the App Store"
              style={{ width: "49%", float: "right" }}
            />
          </a>
        </MobileView>

        {values.ChartData.length > 0 && (
          <Table
            size="small"
            className={classes.table}
            aria-label="simple table"
            style={{ marginTop: 20 }}
          >
            <TableBody>
              <TableRow>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    backgroundColor: darkState ? "#1F253C" : "#EDF0F6",
                    borderBottom: "none",
                  }}
                >
                  Period
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    backgroundColor: darkState ? "#1F253C" : "#EDF0F6",
                    borderBottom: "none",
                  }}
                  align="right"
                >
                  End Balance
                </TableCell>
              </TableRow>

              {values.ChartData.map((row, index) => (
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor:
                        index % 2 === 0
                          ? ""
                          : darkState
                          ? "#1F253C"
                          : "#EDF0F6",
                      borderBottom: "none",
                    }}
                  >
                    <BootstrapTooltip
                      title={CurrentYear + row.YearNum}
                      placement="right"
                      enterTouchDelay={0}
                    >
                      <span style={{ cursor: "pointer" }}>
                        Year {row.YearNum}
                      </span>
                    </BootstrapTooltip>
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor:
                        index % 2 === 0
                          ? ""
                          : darkState
                          ? "#1F253C"
                          : "#EDF0F6",
                      borderBottom: "none",
                    }}
                    align="right"
                  >
                    <BootstrapTooltip
                      enterTouchDelay={0}
                      title={
                        <div>
                          <div style={{ textAlign: "right" }}>
                            {"Contribution: " +
                              dollarFormatInt(row.Contribution.toString())}
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <span style={{ float: "left" }}>Profit:</span>
                            {dollarFormatInt(row.Profit.toString())}
                          </div>
                        </div>
                      }
                      placement="left"
                    >
                      <span style={{ cursor: "pointer" }}>
                        {dollarFormat(row.Total.toString())}
                      </span>
                    </BootstrapTooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}

        <div
          style={{
            marginTop: 20,
            padding: 20,
            backgroundColor: darkState ? "#1F253C" : "#EDF0F6",
            border: "1px solid #DEDEDE",
            borderColor: darkState ? "#151515" : "#DEDEDE",
          }}
        >
          <Typography
            component="h3"
            variant="h4"
            style={{ fontWeight: "bold" }}
          >
            Formulas
          </Typography>
          <Grid container spacing={0} style={{ marginTop: 0 }}>
            <Grid item sm={6} xs={12}>
              <Typography component="h4" variant="h7" style={{ marginTop: 15 }}>
                Compound Interest for Principal:
              </Typography>
              <Typography>
                <b style={{ color: "#4192F7" }}>CI</b> = P × (1 + r/n)
                <sup>nt</sup>
              </Typography>
              <Typography component="h4" variant="h7" style={{ marginTop: 15 }}>
                Future Value of a Series:
              </Typography>
              <Typography>
                <b style={{ color: "#66DD9C" }}>FV</b> = PMT × (((1 + r/n)
                <sup>nt</sup> - 1) / (r/n))
              </Typography>
              <Typography component="h4" variant="h7" style={{ marginTop: 15 }}>
                Total Amount:
              </Typography>
              <Typography>
                <b style={{ color: "#e74c3c" }}>T</b> ={" "}
                <b style={{ color: "#4192F7" }}>CI</b> +{" "}
                <b style={{ color: "#66DD9C" }}>FV</b>
              </Typography>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography component="h4" variant="h7" style={{ marginTop: 15 }}>
                Where:
              </Typography>
              <Typography>
                <b>P</b> = Principal investment amount
              </Typography>
              <Typography>
                <b>PMT</b> = Additional payment per period
              </Typography>
              <Typography>
                <b>r</b> = Annual rate of interest
              </Typography>
              <Typography>
                <b>n</b> = Compound frequency per year
              </Typography>
              <Typography>
                <b>t</b> = Investment time (in years)
              </Typography>
            </Grid>
          </Grid>
        </div>

        <div style={{ textAlign: "center", marginTop: 10 }}>
          <a
            href="/privacy-policy.html"
            style={{
              color: darkState ? "#bcc4dc" : "gray",
              textDecoration: "none",
              margin: 5,
            }}
          >
            Privacy
          </a>
          <a
            href="/terms-and-conditions.html"
            style={{
              color: darkState ? "#bcc4dc" : "gray",
              textDecoration: "none",
              margin: 5,
            }}
          >
            Terms
          </a>
        </div>

        <div style={{ width: "100%", height: 200 }}></div>
      </Container>

      <Dialog
        open={shareOpen}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid container direction="row" alignItems="center">
            <Grid style={{ lineHeight: 1 }} item>
              <CheckCircleOutlineOutlinedIcon />
            </Grid>
            <Grid
              style={{ lineHeight: 1, paddingLeft: 5, fontSize: "1rem" }}
              item
            >
              Calculation Saved
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: 10, display: "block" }}>
            Use the link below to share this result:
          </div>
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            value={"https://compound.li/" + values.ShareKey}
            onFocus={(event) => event.target.select()}
          />
          <div style={{ marginTop: 15, display: "block", textAlign: "right" }}>
            <Button
              variant="contained"
              onClick={() => {
                setShareOpen(false);
              }}
              color="primary"
            >
              Done
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <div style={{ position: "fixed", bottom: 30, right: 30 }}>
        <Tooltip title="Save Result" aria-label="Save" placement="left">
          <Fab color="primary" aria-label="add" onClick={saveData}>
            {!savingData && <SaveIcon />}
            {savingData && <CircularProgress color="secondary" />}
          </Fab>
        </Tooltip>
      </div>
    </ThemeProvider>
  );
}

export default App;
